@import '../../assets/styles/shared';

.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .form {
        position: relative;
        padding: 50px 0;
        width: 30vw;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .infoMessage {
            @include body-text;
            font-style: italic;
            text-align: center;
        }
    }

    .actions {
        width: 30vw;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}
