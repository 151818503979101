.rowContainer {
  margin-bottom:  20px;
  display: flex;
  flex-direction: column;

  h3,p,div {
    padding:  3px 0;
    flex-wrap: wrap;
  }
}

div:last-child {
  margin-bottom:  0;
}

.chipContainer {
  display: flex;
  gap: 10px;
}