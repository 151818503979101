@import '../../../assets/styles/variables';

.container {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.message {
    margin-top: 2rem;
    font-weight: $fw-light;
    color: $clr-accent-tertiary;
    font-size: $fs-body;
}
