@import '../../assets/styles/shared';

.list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  padding-top: 10px;
  padding-bottom: 120px;
}

.card {
  width: 100%;
  height: 150px;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 12px;

  .link {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 150px;

    .title {
      align-self: flex-end;
      padding: 20px;
      font-weight: $fw-light;
      color: $clr-grey-dark;
      font-size: $fs-subtitle;
      text-align: left;
    }

    .image {
      svg {
        border-radius: 0 12px 12px 0;
      }
    }
  }

  &:hover {
    box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.35);
  }

  &:active {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
  }
}
