@import '../../../assets/styles/shared';

.menu {
  position: relative;

  ul {
    position: absolute;
    margin-top: 4px;
    right: 0;

    background: $clr-background;
    border-radius: 6px;
    box-shadow: 0 1px 10px rgba($clr-grey-dark, 0.5);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: max-content;
    padding: 10px;

    z-index: 2;

    li {
      width: 100%;
      button {
        width: 100%;
        text-align: right;
        padding: 10px;
        font-size: $fs-body-md;
        font-weight: $fw-semibold;

        &:hover {
          background: $clr-lighten-primary;
          color: $clr-accent-primary;
        }

        &:disabled {
          color: $clr-onDisabled;
          pointer-events: none;
        }
      }
    }
  }
}
