@import '../../../assets/styles/shared';

.infoContainer {
  padding: 30px 0;
  display: flex;
  gap: 10px;

  .details {
    flex: 1;
    display: grid;
    grid-template-rows: 54px 1fr 1fr 1fr;
    gap: 10px;

    .row {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      border-radius: 10px;

      .title {
        flex: 1;
        padding-left: 10px;
        font-size: 30px;
        font-weight: $fw-light;
      }

      .label {
        @include label-text;
      }

      &.highlighted {
        background: $clr-lighten-tertiary;
        padding: 0 20px;
      }
    }
  }
}

.dangerZone {
  padding: 20px;
  border: 1px solid $clr-danger;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .title {
    @include label-text;
    color: $clr-danger;
    text-transform: uppercase;
  }

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
