@import '../../assets/styles/shared';

.container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 300px;
    background: $clr-accent-primary;
    color: $clr-lighten-tertiary;

    display: flex;
    flex-direction: column;

    .logo {
        display: flex;
        justify-content: flex-end;
        padding: 20px;
    }

    .navLinks {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .link {
        a {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 20px;
        }

        .active,
        .external {
            @include subtitle-text;
            color: $clr-onAccent;
        }
    }
}
