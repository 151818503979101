@import '../../../assets/styles/button-customisation';

.solid {
    @include button-style;

    &.primary {
        @include btn-primary-style;
    }

    &.secondary {
        @include btn-secondary-style;
    }

    &.tertiary {
        @include btn-tertiary-style;
    }

    &.success {
        @include btn-success-style;
    }
    &.danger {
        @include btn-danger-style;
    }

    &:disabled {
        @include btn-disabled-style;
    }
}

.text {
    @include button-style;

    &.primary {
        @include text-btn-primary-style;
    }

    &.secondary {
        @include text-btn-secondary-style;
    }

    &.tertiary {
        @include text-btn-tertiary-style;
    }

    &.success {
        @include text-btn-success-style;
    }
    &.danger {
        @include text-btn-danger-style;
    }

    &:disabled {
        @include text-btn-disabled-style;
    }
}

.FABtn {
    @include fab-style;

    &.primary {
        @include btn-primary-style;
    }

    &.secondary {
        @include btn-secondary-style;
    }

    &.tertiary {
        @include btn-tertiary-style;
    }

    &:disabled {
        @include btn-disabled-style;
    }
}

.icon {
    @include icon-btn-style;

    @include text-btn-tertiary-style;

    &.primary {
        @include text-btn-primary-style;
    }

    &.secondary {
        @include text-btn-secondary-style;
    }

    &.success {
        @include text-btn-success-style;
    }

    &.danger {
        @include text-btn-danger-style;
    }
}
