@import '../../../../assets/styles/shared';

.wrapperContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin:  10px 0;
  flex-wrap: wrap;
  background: $clr-lighten-tertiary;
  border-radius: 10px;
  padding: 15px 40px;
}

.spotNameWrapper {
  display: flex;
}

.spotContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  padding: 20px;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}