@import '../../../assets/styles/input-customisation';

.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    .feedbackArea {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    label {
        @include input-label;
        padding-left: 4px;

        &.disabled {
            color: darken($clr-disabled, 50%);
        }

        &.valid {
            color: $clr-success;
        }

        &.error {
            color: $clr-danger;
        }

        &.required:after {
            content: ' *';
            font-weight: $fw-bold;
            color: $clr-danger;
        }
    }

    .message {
        font-size: $fs-body-sm;
        font-weight: $fw-bold;
        color: $clr-danger;
        padding-right: 2px;
        right: 0;
    }
}

.container {
    @include input-container;
    flex: 1;
    display: flex;
    align-items: center;
    gap: 4px;

    &.disabled {
        border-color: darken($clr-disabled, 2%);
        background-color: $clr-disabled;
    }

    &.valid {
        border-color: #8ed3ab;

        &:focus-within {
            border-color: $clr-success;
        }
    }

    &.error {
        border-color: lighten($clr-danger, 25%);

        &:focus-within {
            border-color: $clr-danger;
        }
    }

    input {
        @include input-text;
        width: 100%;
        background-color: transparent;
        outline: none;
        border: none;
        height: inherit;
    }

    button {
        position: absolute;
        right: 10px;
        top: 14px;
    }

    textarea {
        height: 100%;
        width: 100%;
        border: none;
        overflow: auto;
        outline: none;
        background: none;

        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        resize: none;

        font-size: $fs-body-md;
    }
}

.dropdown {
    position: relative;

    ul {
        @include dropdown-list-container;
        margin-top: 4px;
        border-radius: 10px;
        padding: 4px;
        max-height: 200px;
        z-index: 2;

        li {
            padding: 4px 6px;
            background-color: $clr-background;

            &:hover {
                background-color: $clr-lighten-tertiary;
            }

            button {
                width: 100%;
                text-align: start;
                font-weight: $fw-semibold;
                font-size: $fs-body-md;
            }

            &.noMatch {
                font-style: italic;
                font-size: $fs-body-md;
                pointer-events: none;
            }
        }
    }
}
