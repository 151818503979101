@import '../../../assets/styles/card-customisation';
@import '../../../assets/styles/list-item-customisation';

.listItem {
    @include card-container-style;
    background-color: $clr-background;

    .header {
        @include card-header-style;
        gap: 10px;
        padding: 10px 20px;
        padding-right: 10px;

        .title {
            @include card-header-title-style;
            flex: 1;
        }

        i {
            font-size: $fs-body-sm;
            font-weight: $fw-xlight;
            color: $clr-grey;
        }
    }

    .children {
        @include card-chip-content-style;

        i {
            font-size: $fs-body-md;
        }
    }

    i {
        font-size: $fs-body-sm;
        font-weight: $fw-xlight;
        color: $clr-grey;
    }
}
