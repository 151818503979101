@import './variables';

@mixin title-text {
    font-size: $fs-title;
    font-weight: $fw-xlight;
    line-height: 150%;
}

@mixin subtitle-text {
    font-size: $fs-subtitle;
    font-weight: $fw-bold;
    line-height: 150%;
}

@mixin body-text {
    font-size: $fs-body;
    font-weight: $fw-regular;
    line-height: 150%;
}

@mixin label-text {
    font-size: $fs-body;
    font-weight: $fw-semibold;
    line-height: 150%;
}

@mixin divider-style {
    > div {
        &:after {
            content: '';
            border: 1px solid $clr-grey-light;
            margin-left: 10px;
        }
    }

    > div:last-child {
        &:after {
            border: none;
        }
    }
}

@mixin disabe-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
