@import '../../../assets/styles/controls-customisation';
/* Customize the label (the container) */
.container {
  @include checkbox-style;

  &.disabled {
    @include checkbox-disabled-style;
  }
}

.checkbox {
  @include control-style;

  .checkmark {
    @include checkbox-style;
  }

  &.disabled {
    @include control-disabled-style;
    .checkmark {
      @include checkbox-disabled-style;
    }
  }
}

.radio {
  @include control-style;

  .checkmark {
    @include radio-style;
  }

  &.disabled {
    @include control-disabled-style;
    .checkmark {
      @include radio-disabled-style;
    }
  }
}
