@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/shared';

.chip {
    padding: 0.3rem;
    color: white;
    font-size: $fs-body-sm;
    font-weight: $fw-semibold;
    border-radius: 8px;
    height: min-content;
    display: block;
    cursor: default;
    @include disabe-select;

    &--success {
        background-color: $clr-success;
    }

    &--warning {
        background-color: $clr-danger;
    }
}
