@import './shared';

// Customise styles here
$color: black;
$size: 20px;
$font-weight: 500;

@mixin control-style {
  display: block;
  position: relative;
  padding-left: calc($size + 12px);
  font-weight: $font-weight;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default control */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* On mouse-over, add a grey background color */
  &:hover {
    input ~ .checkmark {
      background-color: lighten($color, 90%);
    }
  }

  /* When the control is checked*/
  input:checked ~ .checkmark {
    background-color: black;

    &:after {
      display: block;
    }
  }
}

@mixin control-disabled-style {
  pointer-events: none;
  color: $clr-onDisabled;

  /* When the checkbox is checked*/
  input:checked ~ .checkmark {
    background-color: $clr-disabled;

    &:after {
      display: block;
    }
  }
}

@mixin checkbox-style {
  position: absolute;
  top: 0;
  left: 0;
  height: $size;
  width: $size;
  background-color: transparent;
  border: 3px solid $color;
  border-radius: 3px;

  /* Create the checkmark/indicator (hidden when not checked) */
  &:after {
    content: '';
    position: absolute;
    display: none;
    // checkmark indicator style when shown
    left: calc(calc($size - 11px) / 2 + 2.5px);
    top: calc(calc($size - 11px) / 2 - 2.5px);
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@mixin checkbox-disabled-style {
  background-color: $clr-disabled;
  border: 3px solid $clr-onDisabled;

  /* Create the checkmark/indicator (hidden when not checked) */
  &:after {
    content: '';
    border: solid $clr-onDisabled;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@mixin radio-style {
  position: absolute;
  top: 0;
  left: 0;
  height: $size;
  width: $size;
  background-color: transparent;
  border-radius: 50%;
  border: 3px solid $color;

  /* Create the checkmark/indicator (hidden when not checked) */
  &:after {
    content: '';
    position: absolute;
    display: none;
    // checkmark indicator style when shown
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
  }
}

@mixin radio-disabled-style {
  background-color: $clr-disabled;
  border: 3px solid $clr-onDisabled;

  &:after {
    content: '';
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $clr-onDisabled;
  }
}
