@import '../../../assets/styles/variables';

.container {
    margin: 0rem 1rem 2rem 1rem;
}

.controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    &__date {
        flex: 1;
        text-align: center;
        color: $clr-accent-primary;
        font-weight: $fw-bold;
        font-size: $fs-body;
    }
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: $clr-accent-primary;
    font-weight: $fw-semibold;
    font-size: $fs-body-md;
}

.calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    border: 1px solid $clr-lighten-primary;
    border-radius: 0.2rem;
}

.cell {
    border-right: 1px solid $clr-lighten-primary;
    border-bottom: 1px solid $clr-lighten-primary;
    min-height: calc((100vh - 250px) / 6);
    cursor: pointer;

    &:hover {
        background-color: $clr-lighten-tertiary;
    }

    &:nth-child(7n) {
        border-right: none;
    }

    &:nth-last-child(-n + 7) {
        border-bottom: none;
    }

    &__header {
        margin: 3px;
        color: $clr-accent-primary;
        font-weight: $fw-bold;
        font-size: $fs-body-sm;
        width: 30px;
        height: 30px;
        text-align: center;

        &--disabled {
            color: $clr-grey-light;
        }

        &--selected {
            background-color: $clr-accent-primary;
            border-radius: 50%;
            color: white;
        }
    }

    &__item {
        border: 1px solid $clr-accent-primary;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 4px;
        padding: 4px;
        color: $clr-accent-primary;

        &--disabled {
            border-color: $clr-onDisabled;
            background-color: $clr-disabled;
            color: $clr-onDisabled;
        }

        &_label {
            font-weight: $fw-regular;
            font-size: $fs-body-sm;
            line-height: 15px;
        }

        &_value {
            font-weight: $fw-bold;
            font-size: $fs-body-sm;
        }
    }
}
