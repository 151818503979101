@import '../../../assets/styles/list-item-customisation';

.container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 162px);
    gap: 20px;

    .inputs {
        padding: 10px 0;
        display: flex;
        gap: 20px;
    }

    .list {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow: auto;
        padding-bottom: 120px;
    }
}
