@import '../../assets/styles/list-item-customisation';

.container {
    height: calc(100vh - 115px);
    display: flex;
    flex-direction: column;

    .inputs {
        padding: 10px 0;
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        gap: 10px;
    }

    .list {
        padding-top: 20px;
        padding-bottom: 120px;
        flex: 1;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}

.item {
    @include item-container-style;
    padding-right: 10px;
    padding-left: 8px;

    .content {
        display: flex;
        align-items: center;
        gap: 8px;

        .info {
            @include item-info-style;

            .details {
                font-size: $fs-body-md;
                color: $clr-grey-dark;
                display: flex;
                align-items: center;
                gap: 10px;

                @include divider-style;
            }
        }
    }

    .actions {
        @include item-actions-style;
    }
}
