@import '../../assets/styles/shared';

.container {
    flex: 1;
    position: sticky;
    top: 115px;
    background-color: $clr-background;
    color: $clr-accent-tertiary;
    font-weight: $fw-bold;
    font-size: $fs-body;
    z-index: 2;

    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        li {
            display: flex;
            align-items: center;

            .active {
                color: $clr-accent-primary;
            }

            a {
                border-radius: 40px;
                padding: 8px 20px;
                &:hover {
                    background-color: $clr-lighten-primary;
                }
            }
        }
    }
}
