@import '../../assets/styles/shared';

.header {
    position: sticky;
    display: flex;
    align-items: center;
    top: 0;
    padding: 20px;
    background: $clr-background;
    z-index: 2;
    @include title-text;
}
