@import '../../../assets/styles/shared';

.container {
    position: absolute;
    font-size: $fs-body-sm;
    font-weight: $fw-semibold;
    text-align: center;
    color: $clr-danger;
    bottom: 0;
    width: 100%;
    padding: 10px 0;
}
