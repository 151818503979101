@import './shared';

@mixin card-container-style {
    display: flex;
    flex-direction: column;
    border: 1px solid $clr-grey-light;
    border-radius: 10px;
    background: $clr-lighten-tertiary;
}

@mixin card-header-style {
    padding: 10px 10px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $clr-onAccent;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid $clr-grey-light;
}

@mixin card-header-title-style {
    @include body-text;
    font-size: $fs-subtitle;
    text-transform: none;
    display: flex;
    flex-direction: column;
    padding-left: 0;
}

@mixin card-header-subtitle-style {
    display: flex;
    gap: 10px;
    font-size: $fs-body-sm;
}

@mixin card-chip-content-style {
    padding: 20px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

@mixin card-list-content-style {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
