@import './variables';

// Customise containers here...
$border-width: 1px;
$border-radius: 10px;
$border-color: $clr-grey-light;
$border-caret-color: $clr-onBackground;
$padding: 10px 8px;

@mixin input-label {
    font-size: $fs-body-sm;
    font-weight: $fw-semibold;
}

@mixin input-text {
    font-size: $fs-body-md;
    font-weight: $fw-regular;

    &::placeholder {
        font-weight: $fw-light;
    }
}

@mixin input-container {
    padding: $padding;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    caret-color: $border-caret-color;

    &:focus-within {
        border-color: darken($border-color, 50%);
    }
}

@mixin dropdown-list-container {
    background-color: $clr-background;
    box-sizing: border-box;
    overflow-y: auto;
    position: absolute;
    border: $border-width solid $border-color;
    border-radius: 6px;
    top: 100%;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    box-shadow: 0 1px 10px rgba($clr-grey-dark, 0.5);
}
