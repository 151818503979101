// GLOBAL CSS VARIABLES

// Global Colors (clr)
$clr-onAccent: white;
$clr-background: white;
$clr-onBackground: black;
$clr-danger: #da534b;
$clr-success: #1ea657;

$clr-disabled: #f2f2f2;
$clr-onDisabled: #b7b7b7;
$clr-grey: #808080;
$clr-grey-light: #cccccc;
$clr-grey-dark: #605b5b;

$clr-accent-primary: #002342;
$clr-lighten-primary: #e5e8eb;
$clr-darken-primary: darken($clr-accent-primary, 10%);

$clr-accent-secondary: black;
$clr-lighten-secondary: #e5e5e5;
$clr-darken-secondary: lighten($clr-accent-secondary, 15%);

$clr-accent-tertiary: #305678;
$clr-lighten-tertiary: #eaeef1;
$clr-darken-tertiary: darken($clr-accent-tertiary, 10%);

// Global Font Weights (fw)
$fw-xlight: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-semibold: 600;
$fw-bold: 700;

// Global Font Sizes (fs)
$fs-title: 50px;
$fs-subtitle: 21px;
$fs-body: 18px;
$fs-body-md: 16px;
$fs-body-sm: 14px;
