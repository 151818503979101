@import '../../../assets/styles/card-customisation';
@import '../../../assets/styles/list-item-customisation';

.listItem {
    @include item-container-style;
    background-color: $clr-background;
    padding-right: 10px;

    .info {
        @include item-info-style;
    }

    .actions {
        @include item-actions-style;
        gap: 10px;
    }
}

.description {
    font-size: $fs-body-sm;
}

.details {
    padding-top: 6px;
    font-size: $fs-body-md;
    color: $clr-grey-dark;
    display: flex;
    align-items: center;
    gap: 10px;

    @include divider-style;
}

.status {
    font-size: $fs-body-sm;
    font-weight: $fw-semibold;

    &.draft {
        color: $clr-grey-dark;
    }

    &.ongoing {
        color: $clr-danger;
    }

    &.completed {
        color: $clr-success;
    }
}
