@import '../../../../assets/styles/shared';

.container {
    margin: 25px 0;
}

.header {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.contextWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $clr-lighten-tertiary;
    margin: 10px 0;
    padding: 0 20px;
    border-radius: 10px;
    height: 60px;
}
