@import '../../../assets/styles/card-customisation';

.dataConfigCard {
    @include card-container-style;

    &.readOnly {
        background: $clr-onAccent;
    }

    .cardHeader {
        @include card-header-style;

        .title {
            @include card-header-title-style;

            .subtitle {
                @include card-header-subtitle-style;
            }
        }

        &.closed {
            border-radius: 10px;
        }
    }

    .cardContent {
        @include card-chip-content-style;
    }

    .cardContentActions {
        padding: 20px;
        padding-top: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
    }
}
