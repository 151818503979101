@import '../../../assets/styles/shared';

.container {
  background: $clr-grey-light;

  &.horizontal {
    height: 1px;
    width: 100%;
  }

  &.vertical {
    width: 1px;
    margin: 4px 0;
  }
}
