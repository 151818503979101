@import '../../../assets/styles/card-customisation';
@import '../../../assets/styles/list-item-customisation';

@mixin header-structure {
    display: flex;
    align-items: center;
    gap: 10px;

    .title {
        @include subtitle-text;
        text-transform: uppercase;
        flex: 1;
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: calc(100vh - 162px);
    overflow: auto;
    padding-top: 20px;

    .uncategorised {
        @include card-container-style;
        background-color: $clr-background;

        .header {
            @include card-header-style;
            gap: 10px;
            padding: 20px;

            .title {
                @include card-header-title-style;
                flex: 1;

                &.group {
                    text-transform: uppercase;
                    font-weight: $fw-semibold;
                }
            }
        }

        .list {
            @include card-chip-content-style;

            i {
                font-size: $fs-body-sm;
                font-weight: $fw-xlight;
                color: $clr-grey;
            }
        }
    }

    .list_header {
        @include header-structure();

        .title {
            padding-left: 8px;
        }
    }

    .teams_list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-bottom: 20px;
    }
}
