@import './shared';

body {
  @include body-text;
  font-family: 'Manrope', sans-serif;

  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: $clr-background;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

a {
  text-decoration: none;
  color: unset;
  cursor: pointer;
}

button {
  font-family: inherit;
  outline: none;
  border: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}
