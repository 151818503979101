@import '../../../assets/styles/variables';

.container {
    padding: 10px 40px;
}

.no_cleaning_record {
    font-weight: $fw-light;
    font-size: $fs-body;

    &__name {
        font-weight: $fw-semibold;
    }
}
