@import '../assets/styles/shared';

.wrapper {
    margin-left: 300px;
}

.header {
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    padding: 20px;
    background: $clr-background;
    z-index: 2;

    .returnButton {
        display: flex;
        align-items: baseline;
    }

    .title {
        @include title-text;
    }
}

.contentWrapper {
    padding: 0 20px;
}

.FABWrapper {
    position: fixed;
    bottom: 20px;
    right: 20px;
}
