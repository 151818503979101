@import '../../../assets/styles/card-customisation';
@import '../../../assets/styles/list-item-customisation';

.dataCard {
  @include card-container-style;

  .cardHeader {
    @include card-header-style;
    gap: 10px;
    padding: 20px;

    .title {
      @include card-header-title-style;
      flex: 1;

      &.group {
        text-transform: uppercase;
        font-weight: $fw-semibold;
      }
    }
  }

  .cardListContent {
    @include card-list-content-style;
  }
}