@import '../../../assets/styles/shared';

@mixin modal-container-style {
    display: flex;
    flex-direction: column;
    background: $clr-background;
    border-radius: 16px;
    box-shadow: -2px 4px 16px 4px rgba(0, 0, 0, 0.25);
}

@mixin modal-header-style {
    padding: 10px 0;
    text-align: center;
    @include subtitle-text;
}

.overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba($clr-grey-dark, 0.5);
    top: 0;
    left: 0;
    z-index: 100;

    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        @include modal-container-style();
        padding: 20px;
        width: 480px;

        .header {
            position: relative;
            @include modal-header-style();
        }

        .modalBody {
            position: relative;
            padding-top: 20px;
            padding-bottom: 50px;

            /* width */
            ::-webkit-scrollbar {
                width: 5px;
            }

            /* Track */
            ::-webkit-scrollbar-track {
                background-color: rgba($clr-grey-dark, 0.2);
                border-radius: 10px;
            }

            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: $clr-accent-secondary;
                border-radius: 5px;
            }
        }

        .modalActions {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }

    .confirmContainer {
        @include modal-container-style();
        padding: 10px;
        width: 300px;

        .header {
            @include modal-header-style();
        }

        .body {
            text-align: center;
            font-size: $fs-body-md;
            font-weight: $fw-light;
        }

        .actions {
            padding: 10px 0;
            display: flex;
            gap: 30px;
            align-items: center;
            justify-content: center;
        }
    }
}

.notificationContainer {
    position: fixed;
    right: 0;
    top: 0;

    z-index: 3000;

    ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 20px;

        .snackbar {
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            background: $clr-background;
            border-radius: 10px;
            box-shadow: -2px 4px 16px 4px rgba(0, 0, 0, 0.25);
            font-weight: $fw-semibold;

            &.error {
                background: lighten($clr-danger, 35%);
                border: 2px solid $clr-danger;
                color: $clr-danger;
            }

            &.success {
                background: lighten($clr-success, 50%);
                border: 2px solid $clr-success;
                color: $clr-success;
            }

            &.info {
                background: $clr-lighten-secondary;
                border: 2px solid $clr-accent-secondary;
                color: $clr-accent-secondary;
            }
        }
    }
}
