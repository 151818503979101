@import './shared';

@mixin item-container-style {
  padding: 10px 20px;
  border: 1px solid $clr-grey-light;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin item-info-style {
  display: flex;
  flex-direction: column;
}

@mixin item-actions-style {
  display: flex;
  gap: 4px;
}
