@import '../../../assets/styles/shared';

.container {
    @include label-text;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 3px 16px;
    border-radius: 8px;

    &.toggle {
        border: 1.5px solid $clr-accent-tertiary;
        background: $clr-lighten-tertiary;
        color: $clr-accent-tertiary;

        &.selected {
            background: $clr-accent-tertiary;
            color: $clr-onAccent;
        }
    }

    &.removable {
        position: relative;
        padding-right: 40px;

        &.solid {
            color: $clr-onAccent;
            background: $clr-accent-tertiary;
        }

        &.transparent {
            color: $clr-onAccent;
            background-color: rgba(255, 255, 255, 0.15);
        }

        &.readOnly {
            padding: 0 15px
        }

        &.clickable {
            cursor: pointer;
        }

        button {
            position: absolute;
            top: 7px;
            right: 10px;
        }
    }
}
