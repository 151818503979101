@import '../../../assets/styles/shared';

.container {
    padding: 0 40px 40px 40px;

    h2 {
        padding: 10px 0;
    }
}

.notesWrapper {
    padding: 10px 10px;
    margin: 10px 0;
    background: $clr-lighten-tertiary;
    border-radius: 10px;
}
