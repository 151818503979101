@import './shared';

// Customise style here
$button-radius: 40px;
$button-padding: 8px 20px;
$fab-radius: 20px;
$fab-size: 80px;

@mixin icon-btn-style {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100%;

    height: 48px;
    width: 48px;

    &:disabled {
        pointer-events: none;
    }
}

@mixin fab-style {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $fab-size;
    width: $fab-size;
    border-radius: $fab-radius;

    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);

    &:disabled {
        box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);
        pointer-events: none;
    }
}

@mixin button-style {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $button-padding;
    border-radius: $button-radius;

    @include label-text;
}

@mixin btn-primary-style {
    background: $clr-accent-primary;
    color: $clr-onAccent;

    &:hover {
        box-shadow: none;
        background: $clr-darken-primary;
        cursor: pointer;
    }
}

@mixin btn-secondary-style {
    background: $clr-accent-secondary;
    color: $clr-onAccent;

    &:hover {
        box-shadow: none;
        background: $clr-darken-secondary;
        cursor: pointer;
    }
}

@mixin btn-tertiary-style {
    background: $clr-accent-tertiary;
    color: $clr-onAccent;

    &:hover {
        box-shadow: none;
        background: $clr-darken-tertiary;
        cursor: pointer;
    }
}

@mixin btn-success-style {
    background: $clr-success;
    color: $clr-onAccent;

    &:hover {
        box-shadow: none;
        background: darken($clr-success, 8%);
        cursor: pointer;
    }
}

@mixin btn-danger-style {
    background: $clr-danger;
    color: $clr-onAccent;

    &:hover {
        box-shadow: none;
        background: darken($clr-danger, 8%);
        cursor: pointer;
    }
}

@mixin btn-disabled-style {
    pointer-events: none;
    background: $clr-disabled;
    color: $clr-onDisabled;
}

@mixin text-btn-primary-style {
    background: transparent;
    color: $clr-accent-primary;

    &:hover {
        background: $clr-lighten-primary;
        cursor: pointer;
    }
}

@mixin text-btn-secondary-style {
    background: transparent;
    color: $clr-accent-secondary;

    &:hover {
        background: $clr-lighten-secondary;
        cursor: pointer;
    }
}

@mixin text-btn-tertiary-style {
    background: transparent;
    color: $clr-accent-tertiary;

    &:hover {
        background: $clr-lighten-tertiary;
        cursor: pointer;
    }
}

@mixin text-btn-success-style {
    background: transparent;
    color: $clr-success;

    &:hover {
        background: lighten($clr-success, 55%);
        cursor: pointer;
    }
}

@mixin text-btn-danger-style {
    background: transparent;
    color: $clr-danger;

    &:hover {
        background: lighten($clr-danger, 38%);
        cursor: pointer;
    }
}

@mixin text-btn-disabled-style {
    pointer-events: none;
    color: $clr-onDisabled;
}
